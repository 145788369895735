import deepmerge from 'deepmerge';
import { config as baseConfig } from './base';
import { logLevels } from '@common/log/logger-types';

// editorial.jiveworld.app
//
// nps deploy.editorial
//

export const config = deepmerge(baseConfig, {
  apiEnv: 'staging',
  caliServerEnv: 'staging',

  // i18n: {
  //   forcedLocale: 'en',
  // },

  analytics: {
    mixpanel: {
      // mixpanel project: internal (black/green)
      projectToken: '5f46a76541c8fe1dc3a65f451180b8e9',
    },
    // segmentWriteKey: 'HP0iVsUQB6F9CawQ6uVEZLD9kpcrZcJ3', // lupa-spa (testing)
    // property: jiveworld preprod, stream name: my.preprod.jiveworld.com stream
    googleAnalyticsKey: 'G-0ELVMT9KDM',
  },

  logging: {
    // minimumLogLevel: logLevels.info,
    minimumLogLevel: logLevels.debug,
  },

  bugsnag: {
    apiKey: '56ed11fed6bfebab28223aa62b630786',
    enabled: true,
  },

  website: {
    // urls: {
    //   marketingSite: 'https://www.staging.jiveworld.app',
    // },
    // not currently relevant - no marketing site peer
    cookieDomain: 'editorial.jiveworld.app',
  },

  lambdaFunctions: {
    enabled: true,
  },

  player: {
    debug: true, // allow the '~' debug mode by default
  },

  soundbites: {
    listEndpoint: 'https://masala-v2-server.editorial.jiveworld.com/excerpts',
    // other props inherited from env.js
  },

  // same as base-devtest
  // defaultL2: 'es',
  catalogs: {
    es: {
      defaultL1: 'en',
      en: 'lupa-review',
    },
    en: {
      defaultL1: 'pt',
      pt: 'english-pt-review',
      es: 'english-es-review',
    },
    default: 'lupa-review',
  },
});
