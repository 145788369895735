// DEPRECATED: this is not necessary in new designs.
import * as React from 'react';
import { styled } from 'naan/stitches.config';
import useIsInViewport from 'use-is-in-viewport';
import { Responsive } from 'naan/primitives/responsive';

const HeaderStyled = styled('div', {
  background: 'transparent',
  position: 'sticky',
  top: 0,
  variants: {
    sticky: {
      false: {
        position: 'static',
        top: 'auto',
      },
    },
    stuck: {
      true: {
        zIndex: 1000,
        background: 'white',
        shadowStyle: 'panel',
        // '&::after': {
        //   content: '',
        //   position: 'absolute',
        //   bottom: '-10px',
        //   left: 0,
        //   right: 0,
        //   height: '10px',
        //   background: 'linear-gradient( $black-alpha-10, $transparent)',
        // },
      },
    },
  },
});

type StickyHeaderProps = {
  children: React.ReactNode;
  stickyMarginTop?: number;
};

const StickyHeaderContainer = ({
  children,
  stickyMarginTop = 40,
}: StickyHeaderProps) => {
  const [isInViewport, ref] = useIsInViewport({
    modTop: `-${stickyMarginTop}px`,
  });

  return (
    <>
      <HeaderStyled
        ref={ref}
        stuck={!isInViewport}
        sticky
        className="sticky-header-container"
      >
        {children}
      </HeaderStyled>
      <div ref={ref} />
    </>
  );
};

type HeaderProps = {
  children: React.ReactNode;
  forceSticky?: boolean;
  stickyMarginTop?: number;
};

export const OldHeaderContainer = ({
  forceSticky = false,
  children,
  stickyMarginTop,
}: HeaderProps) => {
  return (
    <Responsive
      renderDefault={() => (
        <StickyHeaderContainer
          children={children}
          stickyMarginTop={stickyMarginTop}
        />
      )}
      renderLargeAndUp={() =>
        forceSticky ? (
          <StickyHeaderContainer
            children={children}
            stickyMarginTop={stickyMarginTop}
          />
        ) : (
          <HeaderStyled sticky={false}>{children}</HeaderStyled>
        )
      }
    />
  );
};
